// src/screens/NotificationScreen.js
import React from "react";
import { Box } from "@mui/material";
import Notifications from "../components/Notifications";

const NotificationScreen = () => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <Notifications />
    </Box>
  );
};

export default NotificationScreen;
