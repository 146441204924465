// src/components/SignUp.js
import React, { useContext, useState, useEffect } from "react";
import { Box, TextField, Button, Typography, MenuItem } from "@mui/material";
import { AppContext } from "../App";
import { db, auth } from "../config/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { EmailAuthProvider } from "firebase/auth";
import { getAuth, linkWithCredential } from "firebase/auth";

const SignUp = ({ isNotAuthPage, isArtist }) => {
  const {
    dispatch,
    setCurrentUser,
    setUserData,
    navigate,
    setSuccess,
    setError,
    setMessage,
    scrollToTop,
  } = useContext(AppContext);
  const [typeOfUser, setTypeOfUser] = useState("");
  const [artistName, setArtistName] = useState("");
  const [venueName, setVenueName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setErrorMessage] = useState("");

  const userTypes = [
    { name: "Event-Goer", value: "user", collection: "users" },
    { name: "Peforming Artist", value: "artist", collection: "artists" },
    {
      name: "Promoter / Host",
      value: "promoter",
      collection: "promoters",
    },
    {
      name: "Venue Owner",
      value: "venue owner",
      collection: "venueOwners",
    },
  ];

  const handleSignUp = async () => {
    let userTypeObj = userTypes.find((user) => user.value === typeOfUser);
    let userCollection = userTypeObj?.collection;
    let roles = ["user"];

    if (userTypeObj.value !== "user") {
      roles.push(userTypeObj.value);
    }

    const credential = EmailAuthProvider.credential(email, password);

    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage("Passwords do not match");
      setMessage("Passwords do not match");
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (userTypeObj.value === "user" && !isArtist) {
        const foundUser = await db
          .collection("users")
          .where("email", "==", email)
          .get()
          .then((query) => {
            if (query.empty) {
              return null;
            }
            return query.docs.map((doc) => doc.data())[0];
          });

        if (foundUser && foundUser.guest) {
          let tickets = await db
            .collection("users")
            .doc(foundUser.uid)
            .collection("tickets")
            .get()
            .then((query) => query.docs.map((doc) => doc.data()));

          const userData = {
            name,
            phone,
            email,
            uid: user.uid,
            roles: roles,
            guest: false,
            test: false,
            imageUrl: "",
          };
          await db.collection("users").doc(userData.uid).set(userData);
          if (tickets.length > 0) {
            tickets.forEach((ticket) => {
              db.collection("events")
                .doc(ticket.eventId)
                .collection("tickets")
                .add({
                  ...ticket,
                  purchaserId: user.uid,
                });
            });
          }

          await db.collection("users").doc(foundUser.uid).delete();
          dispatch(setCurrentUser(user));
          dispatch(setUserData(userData));
          setSuccess(true);
          setMessage(
            `Account Created successfully. Welcome, ${name.split(" ")[0]}!`
          );
        } else {
          // Handle linking anonymous account to new non-artist user
          linkWithCredential(user, credential)
            .then(async (usercred) => {
              const user = usercred.user;
              const userData = {
                name,
                phone,
                email,
                uid: user.uid,
                roles: ["user"],
                guest: false,
                test: false,
                imageUrl: "",
              };
              await db.collection("users").doc(userData.uid).set(userData);
              dispatch(setCurrentUser(user));
              dispatch(setUserData(userData));
              setSuccess(true);
              setMessage(
                `Account Created successfully. Welcome, ${name.split(" ")[0]}!`
              );
              navigate("/");
              scrollToTop();
            })
            .catch((error) => {
              console.log("Error upgrading anonymous account", error);
              setError(true);
              setMessage(error.message);
            });
        }
      } else {
        linkWithCredential(user, credential)
          .then(async (usercred) => {
            const user = usercred.user;
            console.log("Anonymous account successfully upgraded", user);

            console.log("User ID => ", user.uid);

            let userData = {
              bio: "",
              email,
              guest: false,
              imageUrl: "",
              infoSubmitted: false,
              onboarded: false,
              phone,
              roles,
              test: false,
              uid: user.uid,
              websiteUrl: "",
            };

            if (isArtist || userTypeObj.value === "artist") {
              userData.name = artistName;
              userData.adminEmail = email;
              userData.adminName = name;
            }

            if (userTypeObj.value === "promoter") {
              userData.name = name;
            }

            if (userTypeObj.value === "venue owner") {
              userData.name = name;
              userData.venueName = venueName;
              userData.adminName = name;
            }

            await db.collection(userCollection).doc(userData.uid).set(userData);

            dispatch(setCurrentUser(user));
            dispatch(setUserData(userData));
            setSuccess(true);
            setMessage(
              `Account Created successfully. Welcome, ${name.split(" ")[0]}!`
            );
            if (isArtist || userTypeObj.value !== "user") {
              navigate("/dashboard");
            } else {
              if (!isNotAuthPage) {
                navigate("/");
              }
            }
            scrollToTop();
          })
          .catch((error) => {
            console.log("Error upgrading anonymous account", error);
            setError(true);
            setMessage(error.message);
          });
      }
    } catch (error) {
      console.error("Error during sign up:", error);
      setError(true);
      setMessage(error.message);
    }
  };

  //   const handleKeyDown = (event) => {
  //     if (event.key === "Enter") {
  //       handleSignUp();
  //     }
  //   };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleSignUp();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [artistName, name, phone, email, password, confirmPassword]);

  return (
    <Box sx={{ width: "300px", textAlign: "center" }}>
      <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
        Sign Up
      </Typography>
      {error && (
        <Typography color="error" sx={{ marginBottom: "1rem" }}>
          {error}
        </Typography>
      )}
      <Typography variant="caption" sx={{ marginBottom: "1rem" }}>
        What best describes you?
      </Typography>
      <TextField
        required
        select
        value={typeOfUser}
        onChange={(e) => setTypeOfUser(e.target.value)}
        fullWidth
        //   label="Artist Name"
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
        helperText={!typeOfUser ? "User Type is required" : ""}
      >
        {userTypes.map((userType) => (
          <MenuItem key={userType.value} value={userType.value}>
            {userType.name}
          </MenuItem>
        ))}
      </TextField>
      {(isArtist || typeOfUser === "artist") && (
        <TextField
          required
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          fullWidth
          label="Artist Name"
          variant="outlined"
          sx={{ marginBottom: "1rem" }}
          error={!artistName}
          helperText={!artistName ? "Artist Name is required" : ""}
        />
      )}
      {typeOfUser === "venue owner" && (
        <TextField
          required
          value={venueName}
          onChange={(e) => setVenueName(e.target.value)}
          fullWidth
          label="Venue Name"
          variant="outlined"
          sx={{ marginBottom: "1rem" }}
          error={!venueName}
          helperText={!venueName ? "Venue Name is required" : ""}
        />
      )}
      <TextField
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        label="Full Name"
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
        error={!name}
        helperText={
          isArtist && !name
            ? "Who will be managing this account?"
            : "Full name is required"
        }
      />
      <TextField
        required
        fullWidth
        label="Phone"
        value={phone}
        type="tel"
        onChange={(e) => setPhone(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
        error={!phone}
        helperText={!phone ? "Phone number is required" : ""}
      />
      <TextField
        fullWidth
        required
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
        error={!email}
        helperText={!email ? "Email is required" : ""}
      />
      <TextField
        fullWidth
        required
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="outlined"
        type="password"
        sx={{ marginBottom: "1rem" }}
        error={password.length > 0 && password.length < 6}
        helperText={
          password.length > 0 && password.length < 6
            ? "Password must be at least 6 characters"
            : ""
        }
      />
      {password && password.length >= 6 && (
        <TextField
          required
          fullWidth
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          variant="outlined"
          type="password"
          sx={{ marginBottom: "1rem" }}
          error={password !== confirmPassword}
          helperText={
            password !== confirmPassword ? "Passwords do not match" : ""
          }
          //   onKeyDown={handleKeyDown}
        />
      )}
      <Button
        disabled={
          !typeOfUser ||
          !name ||
          !email ||
          !phone ||
          !password ||
          password.length < 6 ||
          password !== confirmPassword
        }
        variant="contained"
        fullWidth
        onClick={handleSignUp}
      >
        {isArtist ? "Get Started" : "Sign Up"}
      </Button>
    </Box>
  );
};

export default SignUp;
