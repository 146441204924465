// src/components/ContactSupport.js
import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";

const ContactSupport = () => {
  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
        Contact Support
      </Typography>
      <TextField
        fullWidth
        label="Your Message"
        variant="outlined"
        multiline
        rows={4}
        sx={{ marginBottom: "1rem" }}
      />
      <Button variant="contained">Send Message</Button>
    </Box>
  );
};

export default ContactSupport;
