import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Modal,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import { AppContext } from "../App";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectNotificationBanner,
  ConnectBalances,
  ConnectPayoutsList,
} from "@stripe/react-connect-js";
import axios from "axios";

const Payouts = ({
  totalRevenue,
  prevPayout,
  futurePayout,
  prevEvents,
  futureEvents,
  totalProfit,
}) => {
  const { userData, defaultTheme } = useContext(AppContext);
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async (stripeId) => {
      try {
        const response = await axios.post(
          "https://us-central1-ticketshare-16a46.cloudfunctions.net/app/account_session",
          {
            stripeId: userData.stripeId,
          }
        );

        if (response.status === 200) {
          const { client_secret: clientSecret } = response.data;
          return clientSecret;
        } else {
          console.log("An error occurred: ", response.data.error);
          return undefined;
        }
      } catch (error) {
        console.log("An error occurred: ", error.message);
        return undefined;
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: process.env.REACT_APP_STRIPE_LIVE_KEY,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: defaultTheme.palette.primary.main,
          buttonPrimaryColorText: "#ffffff",
        },
      },
    });
  });
  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} style={{ padding: 10 }}>
        <Grid
          item
          container
          xs={12}
          md={12}
          style={{
            padding: 10,
            borderRadius: 8,
            border: "1px solid lightgrey",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "grey",
              width: "100%",
              textAlign: "left",
            }}
          >
            Notifications
          </Typography>
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectNotificationBanner
            // Optional:
            // collectionOptions={{
            //   fields: 'eventually_due',
            //   futureRequirements: 'include',
            // }}
            />
          </ConnectComponentsProvider>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={8} style={{ padding: 10 }}>
        <Grid
          item
          container
          xs={12}
          md={12}
          style={{
            padding: 10,
            borderRadius: 8,
            border: "1px solid lightgrey",
          }}
        >
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectBalances />
          </ConnectComponentsProvider>
        </Grid>
      </Grid>

      <Grid item container xs={12} md={4} style={{ padding: 10 }}>
        <Grid
          item
          container
          xs={12}
          md={12}
          style={{
            padding: 10,
            borderRadius: 8,
            border: "1px solid lightgrey",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              padding: 1,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Typography
                variant="caption"
                style={{
                  fontWeight: "bold",
                  color: "grey",
                }}
              >
                Total Revenue
              </Typography>
            </Grid>
            <Grid item container xs={12} style={{ alignItems: "center" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                ${totalRevenue.toFixed(2)}
              </Typography>
              {totalRevenue - totalProfit !== 0 && (
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: "bold",
                    color: defaultTheme.palette.error.dark,
                    background: `${defaultTheme.palette.error.light}55`,
                    padding: 5,
                    borderRadius: 4,
                    marginLeft: 5,
                  }}
                >
                  -${(totalRevenue - totalProfit).toFixed(2)} Fees
                </Typography>
              )}
              <Typography
                variant="caption"
                color="primary"
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                View Orders
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        style={{
          minHeight: "60dvh",
          padding: 10,
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={12}
          style={{
            height: "fit-content",
            border: "1px solid lightgrey",
            borderRadius: 8,
            padding: 10,
          }}
        >
          <Grid item container xs={12} md={12} style={{ padding: 10 }}>
            <Typography
              variant="caption"
              style={{
                width: "100%",
                fontWeight: "bold",
                color: "grey",
              }}
            >
              Upcoming Events Payouts
            </Typography>
            {/* Table with prevEvents with Event Name, Date, and Revenue headers */}
            {futureEvents.length > 0 ? (
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <ConnectPayoutsList />
              </ConnectComponentsProvider>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: "grey",
                  textAlign: "center",
                }}
              >
                No upcoming events to show
              </Typography>
            )}
          </Grid>

          <Grid item container xs={12} md={12} style={{ padding: 10 }}>
            <Typography
              variant="caption"
              style={{
                width: "100%",
                fontWeight: "bold",
                color: "grey",
              }}
            >
              Previous Events Payouts
            </Typography>
            {prevEvents.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Event Name</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Revenue</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prevEvents.map((event) => (
                      <TableRow
                        key={event.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {event.title}
                        </TableCell>
                        <TableCell align="right">
                          {new Date(
                            event.endDate.seconds * 1000
                          ).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="right">
                          ${event.revenue.profit.toFixed(2)}
                        </TableCell>
                        <TableCell align="right">{"PAID"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: "grey",
                  textAlign: "center",
                }}
              >
                No previous Payouts to show
              </Typography>
            )}
          </Grid>
        </Grid>
        <div style={{ width: "100%", height: "80px" }} />
      </Grid>
    </Grid>
  );
};

export default Payouts;
