// src/components/SalesAnalytics.js
import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";

const SalesAnalytics = () => {
  // Dummy data for sales analytics
  const analyticsData = [
    { title: "Total Sales", value: "$10,000" },
    { title: "Tickets Sold", value: "500" },
    { title: "Revenue", value: "$9,500" },
    { title: "Average Ticket Price", value: "$20" },
  ];

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
        Sales Analytics
      </Typography>
      <Grid container spacing={2}>
        {analyticsData.map((data, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6">{data.title}</Typography>
                <Typography variant="body1">{data.value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SalesAnalytics;
