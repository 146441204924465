// src/components/Hero.js
import React, { useContext, useState } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import { AppContext } from "../App";
import concert from "../assets/concert1.jpeg";
import performer from "../assets/performer.jpeg";
import { db } from "../config/firebaseConfig";

const Hero = ({ homeRef }) => {
  const {
    defaultTheme,
    navigate,
    setSuccess,
    setMessage,
    setError,
    scrollToTop,
  } = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState("");

  const handleSubmit = async () => {
    await db
      .collection("interestedUsers")
      .add({
        name: fullName,
        email,
        phone,
        userType,
      })
      .then(() => {
        setSuccess(true);
        setMessage("Submitted Successfully!");
        setModalOpen(false);
        setEmail("");
        setFullName("");
      })
      .catch((error) => {
        setError(true);
        setMessage("Oops. Looks like there was an issue, please try again.");
      });
  };

  const handleSelect = () => {
    if (userType === "artist") {
      navigate("/auth-artist");
      scrollToTop();
    } else {
      setModalOpen(true);
    }
  };

  return (
    <Grid
      ref={homeRef}
      item
      container
      xs={12}
      sx={{
        height: { xs: "100dvh", md: "90dvh" },
        padding: { xs: "0px 20px", md: 0 },
      }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        background: "black",
      }}
    >
      <img
        src={performer}
        alt=""
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center bottom",
          opacity: 0.25,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <div
        style={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          containerType: "inline-size",
          zIndex: 1,
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontWeight: "900",
            color: defaultTheme.palette.grey.light,
            width: "100%",
            textAlign: "center",
          }}
          sx={{
            fontSize: { xs: "10cqw", md: "5cqw" },
            marginTop: { xs: "-150px", md: "-100" },
          }}
        >
          Your Ticket to Success.
        </Typography>

        <Typography
          variant="h5"
          color="primary"
          style={{
            letterSpacing: "2px",
            width: "100%",
            textAlign: "center",
          }}
          sx={{ fontSize: { xs: "4cqw", md: "1cqw" } }}
        >
          Sell tickets directly to your fans, track your performance, and
          maximize your revenue with TicketShare.
        </Typography>

        <Grid
          item
          container
          xs={12}
          md={8}
          lg={5}
          xl={4}
          style={{
            justifyContent: "center",
            padding: 20,
            borderRadius: 12,
            background: "white",
            alignItems: "center",
          }}
          sx={{ marginTop: { xs: "25%", md: "10%" } }}
        >
          <Typography style={{ marginRight: 5 }}>I'm a(n):</Typography>
          <Select
            sx={{
              width: { xs: "70%", md: "50%" },
              padding: 0,
              height: "40px",
              margin: "0px 5px",
            }}
            variant="outlined"
            value={userType}
            onChange={(e) => {
              console.log(e.target.value);
              setUserType(e.target.value);
            }}
          >
            {[
              { name: "Performing Artist", value: "artist" },
              { name: "Booking Agent", value: "agent" },
              { name: "Promoter", value: "promoter" },
              { name: "Venue Owner", value: "venue owner" },
            ].map((ut) => {
              return (
                <MenuItem key={ut.name} value={ut.value}>
                  {ut.name}
                </MenuItem>
              );
            })}
          </Select>
          {/* <Button
            onClick={() => {
              setModalOpen(true);
            }}
            variant="contained"
            style={{
              background: defaultTheme.palette.white,
              color: defaultTheme.palette.black,
              height: 40,
              marginRight: 10,
            }}
            sx={{ fontSize: { xs: "3cqw", md: ".75cqw" } }}
          >
            I'm a Promoter
          </Button> */}
          <Button
            disabled={userType === ""}
            onClick={handleSelect}
            variant="contained"
            color="primary"
            style={{
              color: defaultTheme.palette.white,
              height: 40,
            }}
            sx={{
              fontSize: { xs: "3cqw", md: ".75cqw" },
              width: { xs: "100%", md: "fit-content" },
              marginTop: { xs: "10px", md: "0px" },
            }}
          >
            Let's Go!
          </Button>
        </Grid>
      </div>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Grid
          item
          container
          xs={11}
          md={7}
          lg={5}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            borderRadius: 12,
            outline: "none",
            padding: 20,
          }}
        >
          <Typography
            style={{ fontWeight: "bold", width: "100%", textAlign: "center" }}
          >
            Thank You for Your Interest!
          </Typography>
          <Typography style={{ width: "100%", marginTop: 10 }}>
            We are currently working hard to build an amazing experience
            tailored just for {userType}s like you. Stay tuned for updates and
            exciting features coming your way soon!
          </Typography>
          <Typography style={{ width: "100%", marginTop: 10 }}>
            We appreciate your patience and can't wait to share what we have in
            store.
          </Typography>
          <Typography style={{ width: "100%", marginTop: 10 }}>
            If you would like to be informed when we go live for {userType}s,
            submit your email below!
          </Typography>

          <Grid item container xs={12}>
            <TextField
              style={{ marginTop: 20 }}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              variant="outlined"
              label="Full Name"
              fullWidth
            />

            <TextField
              style={{ marginTop: 10 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              label="Email"
              fullWidth
            />

            <TextField
              style={{ marginTop: 10 }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              variant="outlined"
              label="Phone"
              fullWidth
            />

            <Button
              style={{ marginTop: 10 }}
              disabled={email === "" || fullName === ""}
              onClick={handleSubmit}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default Hero;
