import PropTypes from "prop-types";

// material-ui
import { Chip, Grid, Stack, Typography, Box, Tooltip } from "@mui/material";

// project import
import MainCard from "./MainCard";

// assets
import { RiseOutlined, FallOutlined } from "@ant-design/icons";

const iconSX = {
  fontSize: "0.75rem",
  color: "inherit",
  marginLeft: 0,
  marginRight: 0,
};

export default function AnalyticCard({
  color = "#1677FF",
  title,
  count,
  percentage,
  profit,
  isLoss,
  extra,
}) {
  const extraText = () => {
    if (extra > 0) {
      return `You made an extra ${extra} this week`;
    } else if (extra < 0) {
      return `You made ${Math.abs(extra)} less this week`;
    } else {
      return `You made the same amount this week`;
    }
  };

  return (
    <MainCard contentSX={{ p: 2.25 }}>
      <Stack spacing={0.5}>
        <Typography variant="p" color="text.secondary">
          {title}
        </Typography>
        <Grid container alignItems="center">
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5" color="inherit">
              {count}
            </Typography>
            {profit && (
              <Tooltip title={`Your estimated profit after fees.`}>
                <Typography
                  variant="body1"
                  color="inherit"
                  style={{ marginLeft: 5, cursor: "pointer" }}
                >
                  (${profit})
                </Typography>
              </Tooltip>
            )}
          </Grid>
          {percentage !== 0 && (
            <Grid item>
              <Chip
                variant="combined"
                icon={
                  isLoss ? (
                    <FallOutlined style={iconSX} />
                  ) : (
                    <RiseOutlined style={iconSX} />
                  )
                }
                label={`${percentage}%`}
                style={{
                  borderRadius: 6,
                  backgroundColor: isLoss ? `#ffbd0035` : `${color}50`,
                  border: `1px solid ${isLoss ? "#c29000" : color}`,
                  color: isLoss ? "#c29000" : color,
                }}
                sx={{ ml: 1.25, pl: 1 }}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Stack>
      <Box sx={{ pt: 2.25 }}>
        <Typography variant="caption" color="text.secondary">
          {extraText()}
        </Typography>
      </Box>
    </MainCard>
  );
}

AnalyticCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.string,
};
