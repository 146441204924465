import React, { useContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { AppContext } from "../App";
import band from "../assets/ts1.png";
import dj from "../assets/ts2.png";
import { ExpandMore } from "@mui/icons-material";

const organizers = [
  {
    title: "Sign Up and Create an Account",
    description:
      "Visit TicketShare’s website and sign up to create your organizer account.",
  },
  {
    title: "Create Your Event",
    description:
      "Navigate to the event creation section and fill out the necessary details, including event name, date, location, and ticket types.",
  },
  {
    title: "Set Up Ticketing",
    description:
      "Specify ticket prices and determine whether the ticketing fee will be absorbed by you or passed on to the customers.",
  },
  {
    title: "Promote Your Event",
    description:
      "Use TicketShare’s tools to promote your event. You can also collaborate with promoters for wider reach.",
  },
  {
    title: "Track Sales and Performance",
    description:
      "Access real-time sales data and performance analytics through your dashboard.",
  },
  {
    title: "Payout",
    description:
      "Receive your earnings via direct deposit or check, automatically initiated 1-5 business days after your event.",
  },
];

const promoters = [
  {
    title: "Sign Up and Create an Account",
    description: "Sign up on TicketShare and create your promoter profile.",
  },
  {
    title: "Find Events to Promote",
    description:
      "Browse and select events that you would like to promote. You can also be invited by organizers.",
  },
  {
    title: "Promote the Event",
    description:
      "Use your unique promoter link to share and sell tickets. Access real-time data and performance analytics through your promoter dashboard.",
  },
  {
    title: "Earn Revenue",
    description:
      "Earn a percentage of the revenue based on the number of tickets sold. The revenue split is tiered based on the organizer's ticket sales.",
  },
];

const customers = [
  {
    title: "Browse Events",
    description:
      "Visit the TicketShare website and browse events by category, location, or date.",
  },
  {
    title: "Purchase Tickets",
    description:
      "Select the event you want to attend, choose the number of tickets, and proceed to checkout.",
  },
  {
    title: "Receive Tickets",
    description:
      "Your tickets will be sent to you via email. You can also access them through your TicketShare account.",
  },
  {
    title: "Attend the Event",
    description:
      "Present your electronic ticket's QR Code at the event for entry. The event coordinator will scan it and check you in. Optionally you may also click the direct link in your email and present the check-in sceen from your device.",
  },
  {
    title: "Enjoy the Event",
    description:
      "Enjoy your event experience and stay tuned for more events on TicketShare.",
  },
];

const About = () => {
  const { defaultTheme, navigate, scrollToTop } = useContext(AppContext);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        justifyContent: "center",
        height: "fit-content",
      }}
    >
      <Grid
        item
        container
        xs={12}
        md={10}
        style={{
          minHeight: "60dvh",
          justifyContent: "center",
          padding: "5%",
          textAlign: "center",
          background: "white",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: { xs: "8cqw", md: "2.5cqw" },
          }}
          variant="h2"
          style={{ fontWeight: "900" }}
        >
          Why Artists Choose TicketShare
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "4cqw", md: ".75cqw" },
            margin: { xs: "20px 0px", md: 0 },
          }}
          style={{ width: "100%" }}
        >
          TicketShare is a secure, reliable, and customizable platform that
          ensures a seamless ticketing experience, backed by dedicated support
          and innovative features. <br />
          Choose TicketShare to elevate your events and connect with your
          audience like never before.
        </Typography>
        <Typography
          color="secondary"
          sx={{ fontSize: { xs: "4cqw", md: ".75cqw" } }}
          style={{ width: "100%", fontWeight: "bold" }}
        >
          No up-front costs, no membership fees, and unlimited access to all of
          the features on our platform:
        </Typography>
        <Grid
          item
          container
          xs={12}
          md={5}
          sx={{
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <ul>
            <li>Ticketing.</li>

            <li>Comprehensive Analytics.</li>

            <li>Secure and Reliable.</li>
          </ul>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={5}
          sx={{
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <ul>
            <li>Direct Fan Engagement.</li>
            <li>Flexible Pricing and Royalties.</li>
            <li>And More!</li>
          </ul>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={5}
        sx={{
          height: { xs: "40dvh", md: "30dvh" },
          justifyContent: { xs: "center", md: "flex-end" },
        }}
        style={{
          textAlign: "center",
          background: "transparent",
          alignItems: "center",
          background: "white",
        }}
      >
        <img style={{ height: "100%", objectFit: "cover" }} src={band} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={5}
        sx={{
          height: { xs: "fit-content", md: "30dvh" },
        }}
        style={{
          textAlign: "left",
          alignItems: "center",
          background: defaultTheme.palette.white,
          padding: 20,
        }}
      >
        <div style={{ with: "100%", height: "fit-content" }}>
          <Typography
            variant="h4"
            sx={{
              width: { xs: "100%", md: "70%" },
            }}
            style={{
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Perform Live Onstage.
            <br /> Get Paid. No Fees.
          </Typography>
          <Typography
            sx={{
              width: { xs: "100%", md: "70%" },
            }}
            style={{
              marginBottom: 20,
            }}
          >
            At TicketShare, we understand the importance of revenue for artists.
            Your customizable TicketShare account gives you all of the tools you
            need for a successful show. Sell tickets directly to your fans,
            eliminating unnecessary fees and ensuring more money goes into your
            pocket.
          </Typography>

          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={() => {
              navigate("/auth-artist");
              scrollToTop();
            }}
          >
            Get Started
          </Button>
        </div>
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={5}
        sx={{
          height: { xs: "40dvh", md: "30dvh" },
          justifyContent: { xs: "center", md: "flex-start" },
          display: { xs: "flex", md: "none" },
        }}
        style={{
          textAlign: "center",
          background: "transparent",
          alignItems: "center",
          background: "white",
        }}
      >
        <img style={{ height: "100%", objectFit: "cover" }} src={dj} />
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={5}
        sx={{
          //   height: { xs: "60dvh", md: "30dvh" },
          minHeight: { xs: "60dvh", md: "30dvh" },
          maxHeight: { xs: "fit-content", md: "50dvh" },
        }}
        style={{
          textAlign: "right",
          justifyContent: "flex-end",
          alignItems: "center",
          background: defaultTheme.palette.white,
          padding: 20,
        }}
      >
        <div
          style={{
            with: "100%",
            height: "fit-content",
            display: "flex",
            alignItems: "flex-end",
            flexFlow: "column",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: { xs: "100%", md: "70%" },
              textAlign: { xs: "left", md: "right" },
            }}
            style={{
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Built by Musicians,
            <br />
            Get Paid. No Up Front Costs.
          </Typography>
          <Typography
            sx={{
              width: { xs: "100%", md: "70%" },
              textAlign: { xs: "left", md: "right" },
            }}
            style={{
              marginBottom: 20,
            }}
          >
            TicketShare was created by musicians, for musicians. We’ve been in
            your shoes and have experienced firsthand how confusing and
            cutthroat the music industry can be. That’s why we created a free
            platform that gives you all of the tools you’ll need to succeed.
            Whether you’re looking to book more real-world live shows, setup a
            paid virtual live stream performance, optimize your promotion or
            just need a better way to manage your workload as a musician, your
            free TicketShare membership will help you every step of the way.
          </Typography>
          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={() => {
              navigate("/auth-artist");
              scrollToTop();
            }}
          >
            Get Started
          </Button>
        </div>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={5}
        sx={{
          minHeight: { xs: "40dvh", md: "30dvh" },
          maxHeight: { xs: "45dvh", md: "50dvh" },
          justifyContent: { xs: "center", md: "flex-start" },
          display: { xs: "none", md: "flex" },
        }}
        style={{
          textAlign: "center",
          background: "transparent",
          alignItems: "center",
          background: "white",
        }}
      >
        <img style={{ height: "100%", objectFit: "cover" }} src={dj} />
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={10}
        lg={8}
        style={{
          minHeight: "60dvh",
          justifyContent: "center",
          padding: "5%",
          textAlign: "center",
          background: "white",
        }}
      >
        <Typography
          color="secondary"
          sx={{
            fontSize: { xs: "6cqw", md: "2cqw" },
          }}
          variant="h2"
          style={{ fontWeight: "900", marginTop: 30 }}
        >
          How TicketShare Works:
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "4cqw", md: ".75cqw" },
            margin: { xs: "20px 0px", md: 0 },
          }}
          style={{ width: "100%", margin: "20px" }}
        >
          TicketShare empowers artists to maximize their revenue, engage
          directly with their fans, and gain valuable insights through
          comprehensive analytics.
        </Typography>
        <Typography
          color="secondary"
          sx={{ fontSize: { xs: "4cqw", md: ".75cqw" } }}
          style={{ width: "100%", fontWeight: "bold" }}
        >
          For Event Organizers:
        </Typography>

        {organizers.map((faq, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {faq.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.description}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}

        <Typography
          color="secondary"
          sx={{ fontSize: { xs: "4cqw", md: ".75cqw" } }}
          style={{ width: "100%", fontWeight: "bold", marginTop: 20 }}
        >
          For Promoters:
        </Typography>

        {promoters.map((faq, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {faq.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.description}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}

        <Typography
          color="secondary"
          sx={{ fontSize: { xs: "4cqw", md: ".75cqw" } }}
          style={{ width: "100%", fontWeight: "bold", marginTop: 20 }}
        >
          For Event Goers:
        </Typography>

        {customers.map((faq, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {faq.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.description}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default About;
