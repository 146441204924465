// Seatchart.jsx
import React, { forwardRef, useEffect, useRef } from "react";
import SeatchartJS from "seatchart";

function setForwardedRef(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

const Seatchart = forwardRef(({ options }, ref) => {
  const seatchartRef = useRef();
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current && !seatchartRef.current) {
      seatchartRef.current = new SeatchartJS(elementRef.current, options);

      setForwardedRef(ref, seatchartRef.current);

      return () => {
        seatchartRef.current = undefined;
        setForwardedRef(ref, undefined);
      };
    }
  }, [options, ref]);

  return <div ref={elementRef} />;
});

export default Seatchart;
