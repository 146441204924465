// App.jsx
import React, { useRef } from "react";
import SeatchartJS from "seatchart";
import Seatchart from "../components/Seatchart";
import "seatchart/dist/seatchart.min.css";
import "../App.css";
import { Button, Grid } from "@mui/material";
import firebase, { db, functions, storage } from "../config/firebaseConfig";
import { testOrders, testOrders2 } from "../utils/contants";

const options = {
  legendVisible: true,
  map: {
    rows: 50,
    columns: 50,
    columnSpacers: [4],
    rowSpacers: [4, 8],
    disabledSeats: [
      { col: 0, row: 8 },
      { col: 1, row: 8 },
    ],
    indexerColumns: { visible: true },
    seatTypes: {
      default: {
        label: "Gen Admission",
        cssClass: "general-admission",
        price: 25,
      },
      VIP: {
        label: "VIP",
        cssClass: "vip",
        price: 50,
        seatRows: [0, 1],
        // seatColumns: [0, 1],
        // seats: [
        //   { col: 1, row: 1 },
        //   { col: 1, row: 2 },
        // ],
      },
    },
    // reservedSeats: [
    //   {
    //     col: 0,
    //     row: 0,
    //   },
    //   {
    //     col: 1,
    //     row: 0,
    //   },
    //   {
    //     col: 2,
    //     row: 0,
    //   },
    //   {
    //     col: 3,
    //     row: 0,
    //   },
    //   {
    //     col: 4,
    //     row: 0,
    //   },
    //   {
    //     col: 5,
    //     row: 0,
    //   },
    // ],
  },
  cart: {
    currency: "$",
    visible: true,
  },
};

const TestScreen = () => {
  const seatchartRef = useRef(null);

  const handleClick = () => {
    const index = { row: 0, col: 6 };
    const seat = seatchartRef.current?.getSeat(index);

    seatchartRef.current?.setSeat(index, {
      state: seat?.state === "selected" ? "available" : "selected",
    });
  };

  const handleTestAPI = () => {
    const testAPI = firebase.functions().httpsCallable("mercuryTest");
    testAPI({ apiToken: process.env.REACT_APP_MERCURY_API, type: "recipients" })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   async function callCloudFunction() {
  //     try {
  //         const response = await axios.get('https://us-central1-ticketshare-16a46.cloudfunctions.net/mercuryTest');
  //         console.log(response.data);
  //     } catch (error) {
  //         console.error('Error calling Cloud Function:', error);
  //     }
  // }

  const calculateOrderData1 = (orders) => {
    const totalTicketsSold = orders.reduce(
      (acc, order) => acc + order.tickets.length,
      0
    );
    const totalOrders = orders.length;
    const totalRevenue = orders.reduce((acc, order) => {
      return acc + order.orderTotal;
      //   return acc + order.orderTotal;
    }, 0);
    const totalProfit = orders.reduce((acc, order) => {
      //   let amtOfTix = order.tickets.length;
      let ticketFees = order.tickets.reduce((ticketsAcc, ticket) => {
        return ticketsAcc + ticket.ticketFee;
      }, 0);
      //   console.log("TICKET FEES =>", ticketFees);
      let stripeFee = order.orderTotal * 0.029 + 0.3;
      let totalFees = ticketFees + stripeFee;
      let earnings = order.orderTotal - totalFees;
      return acc + earnings;
      //   return acc + order.orderTotal;
    }, 0);

    const totalStripeFees = orders.reduce((acc, order) => {
      let stripeFee = order.orderTotal * 0.029 + 0.3;
      return acc + stripeFee;
    }, 0);

    const totalPlatformFees = orders.reduce((acc, order) => {
      let ticketFees = order.tickets.reduce((ticketsAcc, ticket) => {
        return ticketsAcc + ticket.ticketFee;
      }, 0);
      return acc + ticketFees;
    }, 0);

    const uniqueUserIds = new Set(orders.map((order) => order.purchaserId));
    const totalUsers = uniqueUserIds.size;

    console.log({
      totalTicketsSold,
      totalOrders,
      totalRevenue,
      totalUsers,
      totalProfit,
      totalStripeFees,
      totalPlatformFees,
    });

    return {
      totalTicketsSold,
      totalOrders,
      totalRevenue,
      totalUsers,
      totalProfit,
      totalStripeFees,
      totalPlatformFees,
    };
  };

  const calculateOrderData2 = (orders) => {
    const totalTicketsSold = orders.reduce(
      (acc, order) => acc + order.tickets.length,
      0
    );
    let allTickets = [];
    orders.forEach((order) => {
      order.tickets.forEach((ticket) => {
        allTickets.push(ticket);
      });
    });
    const totalOrders = orders.length;
    const totalRevenue = allTickets.reduce((acc, ticket) => {
      if (!ticket.refunded) {
        return acc + ticket.ticketPrice + ticket.ticketFee;
      } else {
        return acc;
      }
    }, 0);

    const totalProfit = orders.reduce((acc, order) => {
      let orderTotal = order.tickets.reduce((ticketsAcc, ticket) => {
        if (!ticket.refunded) {
          return ticketsAcc + ticket.ticketPrice + ticket.ticketFee;
        } else {
          return ticketsAcc; //+ ticket.ticketFee;
        }
      }, 0);
      let ticketFees = order.tickets.reduce((ticketsAcc, ticket) => {
        if (!ticket.refunded) {
          return ticketsAcc + ticket.ticketFee;
        } else {
          return ticketsAcc;
        }
      }, 0);
      let stripeFee = orderTotal * 0.029 + 0.3;
      let totalFees = ticketFees + stripeFee;
      let earnings = orderTotal - totalFees;
      return acc + earnings;
    }, 0);

    const totalStripeFees = orders.reduce((acc, order) => {
      let stripeFees = order.tickets.reduce((ticketsAcc, ticket) => {
        if (!ticket.refunded) {
          return (
            ticketsAcc + ((ticket.ticketFee + ticket.ticketPrice) * 0.028 + 0.3)
          );
        } else {
          return ticketsAcc;
        }
      }, 0);
      return acc + stripeFees;
    }, 0);

    const totalPlatformFees = orders.reduce((acc, order) => {
      let ticketFees = order.tickets.reduce((ticketsAcc, ticket) => {
        return ticketsAcc + ticket.ticketFee;
      }, 0);
      return acc + ticketFees;
    }, 0);

    console.log({
      tickets: allTickets,
      totalTicketsSold,
      totalOrders,
      totalRevenue,
      totalProfit,
      totalStripeFees,
      totalPlatformFees,
    });

    return {
      tickets: allTickets,
      totalTicketsSold,
      totalOrders,
      totalRevenue,
      totalProfit,
      totalStripeFees,
      totalPlatformFees,
    };
  };

  return (
    <Grid
      item
      container
      xs={12}
      style={{ paddingTop: 100, justifyContent: "center" }}
    >
      <Button onClick={handleTestAPI}>Toggle Seat</Button>
      <Button onClick={() => calculateOrderData1(testOrders)}>Orders 1</Button>
      <Button onClick={() => calculateOrderData2(testOrders)}>Orders 2</Button>

      {/* <Seatchart ref={seatchartRef} options={options} /> */}
    </Grid>
  );
};

export default TestScreen;
