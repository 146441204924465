// src/screens/ShowsScreen.js
import React from "react";
import { Box, Grid } from "@mui/material";
import Events from "../components/Events";

const ShowsScreen = () => {
  return (
    <Grid item container xs={12} md={10} sx={{ marginTop: "70px" }}>
      <Events />
    </Grid>
  );
};

export default ShowsScreen;
