// src/components/ManageEvents.js
import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  AccordionActions,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Modal,
  IconButton,
  Paper,
} from "@mui/material";
import ViewEditEvent from "./ViewEditEvent";
import {
  Add,
  Download,
  FilterList,
  KeyboardArrowDown,
  Visibility,
} from "@mui/icons-material";
import { AppContext } from "../App";
import { InfoCircleOutlined } from "@ant-design/icons";
import { db } from "../config/firebaseConfig";
import MainCard from "./MainCard";
import OrdersTable from "./OrdersTable";
import EventScreen from "../screens/EventScreen";
import PerformanceAgreement from "./PerformanceAgreement";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ManageEvents = ({ isArtist, orders }) => {
  const [viewEditEventOpen, setViewEditEventOpen] = useState(false);
  const [previewEventOpen, setPreviewEventOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const {
    events,
    setEvents,
    defaultTheme,
    userData,
    setMessage,
    setError,
    setInfo,
    setSuccess,
    isAdmin,
  } = useContext(AppContext);
  const [viewTerms, setViewTerms] = useState(false);

  const handleViewEditEventClose = () => {
    setViewEditEventOpen(!viewEditEventOpen);
    setSelectedEvent(null);
  };

  const cancelEvent = async (eventId) => {
    try {
      let eventsCopy = events.slice();
      let idx = eventsCopy.findIndex((e) => e.id === eventId);
      let canceledEvent = eventsCopy.find((e) => e.id === eventId);
      await db
        .collection("events")
        .doc(eventId)
        .update({
          canceled: true,
        })
        .then(() => {
          canceledEvent.canceled = true;
          eventsCopy[idx] = canceledEvent;
          setInfo(true);
          setMessage("Event canceled successfully");
          setEvents(eventsCopy);
          setCancelModal(false);
          setSelectedEvent(null);
        });
    } catch (error) {
      setError(true);
      setMessage(error.message);
    }
  };

  const exportAsCSV = async (orders) => {
    try {
      //headers
      const headers = [
        "Event",
        "Date",
        "Puchaser Name",
        "Purchaser Email",
        "Ticket Type",
        "Ticket ID",
        "Checked In",
      ];
      //for each order, get each ticket and add a new row
      const rows = orders.map((order) => {
        return order.tickets.map((ticket) => {
          return [
            order.eventName,
            new Date(order.eventDate.seconds * 1000).toLocaleDateString(),
            order.purchaserName,
            order.purchaserEmail,
            ticket.ticketName,
            ticket.ticketId,
            "",
          ];
        });
      });
      //flatten the rows array
      const flattenedRows = rows.flat();

      //!   //create a new workbook
      //   const wb = XLSX.utils.book_new();
      //   //add the headers to the workbook
      //   XLSX.utils.book_append_sheet(
      //     wb,
      //     XLSX.utils.aoa_to_sheet(headers),
      //     "Sheet1"
      //   );
      //   //add the rows to the workbook
      //   XLSX.utils.book_append_sheet(
      //     wb,
      //     XLSX.utils.aoa_to_sheet(flattenedRows),
      //     "Sheet1"
      //   );
      //   //save the workbook as a file
      //   XLSX.writeFile(wb, "orders.xlsx");
      //   setSuccess(true);
      //   setMessage("Orders exported successfully");

      //!   //create a csv file
      const csvContent =
        "data:text/csv;charset=utf-8," +
        headers.join(",") +
        "\n" +
        flattenedRows.join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `${orders[0].eventName}-ticketshare-orders.csv`.replace(" ", "-")
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSuccess(true);
      setMessage("Orders exported successfully");
    } catch (error) {
      setError(true);
      setMessage(error.message);
    }
  };

  const exportAsPDF = async (orders, eventTitle) => {
    try {
      const doc = new jsPDF("p", "mm", "a4");
      let rows = [];

      orders.forEach((order) => {
        order.tickets.forEach((ticket) => {
          rows.push([
            order.purchaserName,
            order.purchaserEmail,
            ticket.ticketName,
            order.orderNumber,
          ]);
        });
      });

      rows.sort((a, b) => {
        if (a[2] < b[2]) return -1;
        if (a[2] > b[2]) return 1;
        return a[0].localeCompare(b[0]);
      });

      console.log(rows);

      doc.setFontSize(18);
      doc.text(eventTitle, 14, 20);
      doc.setFontSize(12);
      doc.autoTable({
        head: [
          ["Purchaser Name", "Purchaser Email", "Ticket Name", "Order Number"],
        ],
        body: rows,
        startY: 30,
        margin: { top: 10, bottom: 10, left: 14, right: 14 },
        didDrawPage: (data) => {
          doc.text(
            `Page ${doc.internal.getNumberOfPages()}`,
            data.settings.margin.left,
            doc.internal.pageSize.height - 10
          );
        },
      });

      doc.save(`${eventTitle}_orders.pdf`);
    } catch (error) {
      setError(true);
      setMessage("Failed to generate PDF");
    }
  };

  const handleViewTerms = (event) => {
    setSelectedEvent(event);
    setViewTerms(true);
  };

  const handleAcceptAgreement = async (event) => {
    try {
      let artistsCopy = event.artists.slice();
      const idx = artistsCopy.findIndex((a) => a.uid === userData.uid);
      artistsCopy[idx].confirmed = true;
      artistsCopy[idx].dateConfirmed = new Date();
      await db
        .collection("events")
        .doc(event.id)
        .update({
          artists: artistsCopy,
        })
        .then(() => {
          event.artists = artistsCopy;
          setInfo(true);
          setSuccess(true);
          setMessage("Agreement accepted successfully");
          let eventsCopy = events.slice();
          eventsCopy[events.findIndex((e) => e.id === event.id)] = event;
          setEvents(eventsCopy);
          setPreviewEventOpen(false);
          setViewTerms(false);
        });
    } catch (error) {
      setError(true);
      setMessage(
        "Oops, there was an error accepting this agreement. Please try again later."
      );
    }
  };

  return (
    <Grid item container xs={12} sx={{ marginBottom: "2rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          marginBottom: 1,
        }}
      >
        <Typography variant="h6">
          Manage Events {events && events.length > 0 && `(${events.length})`}
        </Typography>
        <Button
          variant="contained"
          sx={{ marginBottom: "1rem", ml: 2 }}
          onClick={() => setViewEditEventOpen(true)}
        >
          <Add style={{ marginRight: 5 }} />
          New Event
        </Button>
      </Box>

      <Grid
        item
        container
        xs={12}
        spacing={2}
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Typography variant="body1" sx={{ pl: 2 }}>
          Filter
        </Typography>
        <IconButton onClick={() => {}}>
          <FilterList />
        </IconButton>
      </Grid>

      {events && events.length > 0 ? (
        <Grid container spacing={2}>
          {events.map((event, index) => {
            const eventOrders = orders.filter(
              (order) => order.eventId === event.id
            );
            const totalRevenue = eventOrders.reduce(
              (acc, order) => acc + order.orderTotal,
              0
            );
            const ticketsSold = eventOrders.reduce(
              (acc, order) => acc + order.tickets.length,
              0
            );

            const isActOnShow = event?.artists?.find(
              (artist) => artist.uid === userData?.uid
            );
            const isPending = isActOnShow && !isActOnShow.confirmed;
            return (
              <Grid item xs={12} key={index}>
                <Accordion style={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        //   justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          height: 50,
                          width: 90,
                          objectFit: "cover",
                          marginRight: 5,
                          borderRadius: 8,
                        }}
                        src={event.image}
                      />
                      <div style={{ display: "flex", flexFlow: "column" }}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "500", textAlign: "left" }}
                        >
                          {event.title}
                          {event.canceled && (
                            <Tooltip title="This event has been canceled">
                              <InfoCircleOutlined
                                style={{ color: "red", marginLeft: 5 }}
                              />
                            </Tooltip>
                          )}
                          {isPending && (
                            <Tooltip title="Action Needed!">
                              <InfoCircleOutlined style={{ color: "red" }} />{" "}
                              <i style={{ color: "grey" }}>
                                Pending Acceptance
                              </i>
                            </Tooltip>
                          )}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ textAlign: "left" }}
                        >
                          {`Date: ${new Date(
                            event.startDate.seconds * 1000
                          ).toLocaleDateString()} | Guests: ${ticketsSold} | Sales: $${totalRevenue.toFixed(
                            2
                          )}`}
                        </Typography>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item container xs={12} style={{}}>
                      {!event.canceled && (
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent={"flex-end"}
                        >
                          {event?.promoter?.uid !== userData?.uid &&
                          !userData?.roles?.includes("admin") ? (
                            <>
                              <Button
                                onClick={() => handleViewTerms(event)}
                                variant="contained"
                                style={{ marginRight: 5 }}
                              >
                                {isPending ? "Accept Terms" : "View Terms"}
                              </Button>

                              <Button
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setPreviewEventOpen(true);
                                }}
                                variant="contained"
                                color="secondary"
                                style={{ marginRight: 5 }}
                              >
                                <Visibility style={{ marginRight: 5 }} />{" "}
                                Preview
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setPreviewEventOpen(true);
                                }}
                                variant="contained"
                                color="secondary"
                                style={{ marginRight: 5 }}
                              >
                                <Visibility style={{ marginRight: 5 }} />{" "}
                                Preview
                              </Button>

                              <Button
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setViewEditEventOpen(true);
                                }}
                                variant="contained"
                                color="primary"
                                style={{ marginRight: 5 }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setCancelModal(true);
                                }}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        </Grid>
                      )}

                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ marginTop: 20, marginBottom: 10 }}
                      >
                        <Grid
                          item
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography variant="h5" style={{}}>
                            Recent Orders
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              flexFlow: "row",
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              style={{ marginRight: 5, borderRadius: 40 }}
                              onClick={() =>
                                exportAsPDF(eventOrders, event.title)
                              }
                            >
                              <Download style={{ marginRight: 5 }} />
                              PDF
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              style={{ borderRadius: 40 }}
                              onClick={() => exportAsCSV(eventOrders)}
                            >
                              <Download style={{ marginRight: 5 }} />
                              CSV
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item />
                      </Grid>
                      <Grid item container xs={12}>
                        <MainCard
                          sx={{ width: "100%", height: "60dvh" }}
                          content={false}
                        >
                          <OrdersTable orders={eventOrders} />
                        </MainCard>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      <Modal
        open={previewEventOpen}
        onClose={() => {
          setPreviewEventOpen(false);
          setSelectedEvent(null);
        }}
      >
        <Grid
          item
          container
          xs={11}
          md={10}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: 12,
            background: "white",
            padding: 20,
          }}
        >
          <Typography
            variant={"h4"}
            style={{
              width: "100%",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Previewing: {selectedEvent?.title}
          </Typography>
          <Grid
            item
            container
            xs={12}
            style={{
              maxHeight: "75dvh",
              overflowY: "scroll",
              justifyContent: "center",
            }}
          >
            <EventScreen previewEvent={selectedEvent} />
          </Grid>
        </Grid>
      </Modal>

      <Modal open={viewTerms} onClose={() => setViewTerms(false)}>
        <Grid
          item
          container
          xs={11}
          md={8}
          lg={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: 12,
            background: "white",
            padding: 20,
          }}
        >
          {selectedEvent && (
            <>
              <Typography
                variant={"h4"}
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Terms and Conditions
              </Typography>
              <Typography
                variant={"h6"}
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {selectedEvent.title}
              </Typography>

              <Grid
                item
                container
                xs={12}
                component={Paper}
                style={{
                  borderRadius: 4,
                  maxHeight: "75dvh",
                  overflowY: "scroll",
                  padding: 20,
                }}
              >
                <PerformanceAgreement
                  event={selectedEvent}
                  paymentSettings={selectedEvent.paymentSettings}
                  onAccept={handleAcceptAgreement}
                  isPending={selectedEvent?.artists?.find(
                    (a) => a?.uid === userData?.uid && !a?.confirmed
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Modal>

      <Modal
        open={cancelModal}
        onClose={() => {
          setSelectedEvent(null);
          setCancelModal(false);
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={7}
          lg={5}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            borderRadius: 12,
            padding: 20,
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            style={{
              //   fontWeight: "bold",
              width: "100%",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Are you sure you want to cancel this event?
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
              width: "100%",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            {selectedEvent?.title}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              cancelEvent(selectedEvent.id);
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedEvent(null);
              setCancelModal(false);
            }}
          >
            No
          </Button>
        </Grid>
      </Modal>

      <ViewEditEvent
        isArtist={isArtist}
        open={viewEditEventOpen}
        setClose={handleViewEditEventClose}
        event={selectedEvent}
        events={events}
        setEvents={setEvents}
      />
    </Grid>
  );
};

export default ManageEvents;
