// src/screens/AdminScreen.js
import React from "react";
import { Box } from "@mui/material";
import ManageEvents from "../components/ManageEvents";
import SalesAnalytics from "../components/SalesAnalytics";

const AdminScreen = () => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <ManageEvents />
      <SalesAnalytics />
    </Box>
  );
};

export default AdminScreen;
