import React from "react";
import { Container, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container style={{ paddingTop: 120, paddingBottom: 50 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy for TicketShare
      </Typography>
      <Typography variant="body1" paragraph>
        Effective Date: 6/3/2024
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to TicketShare, a CUTI App product. We respect your privacy and
        are committed to protecting your personal information. This Privacy
        Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website or use our services. Please read
        this Privacy Policy carefully. If you do not agree with the terms of
        this Privacy Policy, please do not access the site or use our services.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Personal Data:</strong> We may collect personally identifiable
        information, such as your name, email address, phone number, and payment
        information.
        <br />
        <strong>Usage Data:</strong> We collect information automatically when
        you access the website. This includes your IP address, browser type,
        operating system, access times, and pages viewed.
        <br />
        <strong>Cookies and Tracking Technologies:</strong> We use cookies and
        similar tracking technologies to track activity on our website and hold
        certain information. You can instruct your browser to refuse all cookies
        or to indicate when a cookie is being sent.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use the information we collect in the following ways:
        <ul>
          <li>To provide, operate, and maintain our services.</li>
          <li>To improve, personalize, and expand our services.</li>
          <li>To understand and analyze how you use our services.</li>
          <li>
            To develop new products, services, features, and functionality.
          </li>
          <li>
            To communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the service, and for
            marketing and promotional purposes.
          </li>
          <li>To process your transactions and manage your orders.</li>
          <li>To send you emails.</li>
          <li>To find and prevent fraud.</li>
        </ul>
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. How We Share Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We may share your information in the following situations:
        <ul>
          <li>
            With service providers to perform services on our behalf, such as
            payment processing, data analysis, email delivery, hosting services,
            customer service, and marketing assistance.
          </li>
          <li>
            With business partners to offer you certain products, services, or
            promotions.
          </li>
          <li>
            To comply with legal obligations, to protect and defend our rights
            or property, to prevent fraud, and to protect against legal
            liability.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Security of Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use administrative, technical, and physical security measures to
        protect your personal information. While we have taken reasonable steps
        to secure the personal information you provide to us, please be aware
        that despite our efforts, no security measures are perfect or
        impenetrable.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Your Privacy Rights
      </Typography>
      <Typography variant="body1" paragraph>
        Depending on your location, you may have the following rights regarding
        your personal information:
        <ul>
          <li>
            The right to access – You have the right to request copies of your
            personal information.
          </li>
          <li>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate or complete
            information you believe is incomplete.
          </li>
          <li>
            The right to erasure – You have the right to request that we erase
            your personal information, under certain conditions.
          </li>
          <li>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal information, under
            certain conditions.
          </li>
          <li>
            The right to object to processing – You have the right to object to
            our processing of your personal information, under certain
            conditions.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" gutterBottom>
        7. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on
        this page.
      </Typography>
      <Typography variant="h6" gutterBottom>
        8. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this Privacy Policy, please contact us
        at:
        <ul>
          <li>Email: antony@cutiapp.com</li>
          {/* <li>Phone: [Insert Contact Email]</li> */}
        </ul>
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
