// src/components/Login.js
import React, { useContext, useState, useEffect } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { db, auth } from "../config/firebaseConfig";
import { AppContext } from "../App";

const Login = ({ isNotAuthPage, close, isArtist }) => {
  const {
    setUserData,
    setCurrentUser,
    dispatch,
    setSuccess,
    setMessage,
    setError,
    navigate,
    scrollToTop,
  } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      const userCredentials = await auth.signInWithEmailAndPassword(
        email,
        password
      );

      let userData;

      await db
        .collection("artists")
        .doc(userCredentials.user.uid)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            userData = doc.data();
            dispatch(setCurrentUser(userCredentials.user));
            dispatch(setUserData(userData));
          } else {
            await db
              .collection("promoters")
              .doc(userCredentials.user.uid)
              .get()
              .then(async (doc) => {
                if (doc.exists) {
                  userData = doc.data();
                  dispatch(setCurrentUser(userCredentials.user));
                  dispatch(setUserData(userData));
                } else {
                  await db
                    .collection("users")
                    .doc(userCredentials.user.uid)
                    .get((doc) => {
                      if (doc.exists) {
                        userData = doc.data();
                        dispatch(setCurrentUser(userCredentials.user));
                        dispatch(setUserData(userData));
                      } else {
                        return null;
                      }
                    });
                }
              });
          }
        });

      setSuccess(true);
      setMessage("Login Successful");
      if (!isNotAuthPage) {
        if (isArtist) {
          navigate("/dashboard");
        } else {
          navigate("/");
        }
        scrollToTop();
      }
    } catch (error) {
      setError(true);
      setMessage(error.message);
    }
  };

  //   const handleKeyDown = (event) => {
  //     if (event.key === "Enter") {
  //       handleLogin();
  //     }
  //   };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [email, password]);

  return (
    <Box sx={{ width: "300px", textAlign: "center" }}>
      <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
        Login
      </Typography>
      <TextField
        fullWidth
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
      />
      <TextField
        fullWidth
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="outlined"
        type="password"
        sx={{ marginBottom: "1rem" }}
        // onKeyDown={handleKeyDown}
      />
      <Button
        disabled={!email || !password}
        onClick={handleLogin}
        variant="contained"
        fullWidth
      >
        Login
      </Button>
      {isNotAuthPage && (
        <Button
          style={{ marginTop: 20 }}
          disabled={!email || !password}
          onClick={close}
          variant="contained"
          color="secondary"
          fullWidth
        >
          Continue as Guest
        </Button>
      )}
    </Box>
  );
};

export default Login;
