import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  IconButton,
  Chip,
  Modal,
} from "@mui/material";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
import {
  TimerOutlined,
  Search,
  Clear,
  ArrowForward,
  ArrowBack,
  FilterList,
  LocationOnRounded,
} from "@mui/icons-material";
import { eventTags, locationsUS } from "../utils/contants"; // Assuming you have a list of possible tags

const Events = () => {
  const { events, adminUIDs, userData } = useContext(AppContext);
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [searchFilterEvents, setSearchFilterEvents] = useState(null);
  const [tagsFilters, setTagsFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(24); // Display 24 events per page
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    if (events.length > 0 && !upcomingEvents) {
      let upcoming = events.filter(
        (e) =>
          new Date(e.startDate.seconds * 1000).getTime() > new Date().getTime()
      );

      let visible;
      if (userData) {
        if (adminUIDs.includes(userData.uid)) {
          visible = upcoming;
        } else {
          visible = upcoming.filter(
            (x) =>
              (x.draft === false && !x.test && !x.isHidden) ||
              x.artistId === userData.uid
          );
        }
      } else {
        visible = upcoming.filter((x) => !x.draft && !x.test && !x.isHidden);
      }

      setUpcomingEvents(visible);
      setSearchFilterEvents(visible); // Initialize searchFilterEvents with all upcoming events
    }
  }, [events, upcomingEvents]);

  useEffect(() => {
    if (tagsFilters && upcomingEvents) {
      applyTagFilters();
    }
  }, [tagsFilters]);

  const handleSearch = () => {
    if (upcomingEvents) {
      const filtered = upcomingEvents.filter((event) => {
        const searchLower = searchTerm.toLowerCase();
        return (
          event.title.toLowerCase().includes(searchLower) ||
          (event.teaser && event.teaser.toLowerCase().includes(searchLower))
        );
      });
      setSearchFilterEvents(filtered);
      setCurrentPage(1); // Reset to the first page on search
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    setTagsFilters([]);
    setSelectedLocation("");
    setSearchFilterEvents(upcomingEvents);
    setCurrentPage(1); // Reset to the first page on clear
  };

  const handleTagClick = (tag) => {
    setTagsFilters((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  const applyTagFilters = () => {
    const filtered = upcomingEvents.filter((event) =>
      tagsFilters.every((tag) => event.tags.includes(tag))
    );
    setSearchFilterEvents(filtered);
    setCurrentPage(1); // Reset to the first page on applying filters
    setFilterModalOpen(false);
  };

  const handleLocationChange = (event) => {
    const selectedLoc = event.target.value;
    console.log(selectedLoc);
    setSelectedLocation(selectedLoc);

    if (upcomingEvents) {
      const filtered = upcomingEvents.filter((event) => {
        return selectedLoc
          ? event.location.toLowerCase().includes(selectedLoc.toLowerCase())
          : true;
      });
      setSearchFilterEvents(filtered);
      setCurrentPage(1); // Reset to the first page after filtering
    }
  };

  // Pagination logic
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = searchFilterEvents
    ? searchFilterEvents.slice(indexOfFirstEvent, indexOfLastEvent)
    : [];

  const paginateForward = () => {
    if (currentPage * eventsPerPage < searchFilterEvents.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const paginateBackward = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Grid item container xs={12} style={{ paddingTop: 20 }}>
      <Grid
        item
        container
        xs={12}
        sx={{
          padding: 2,
          //   marginBottom: "20px",
          justifyContent: { xs: "flex-end", md: "center" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "calc(100% - 200px)" },
            display: "flex",
            marginRight: { xs: 0, md: "10px" },
          }}
        >
          <TextField
            label="Search Events"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: { xs: "70%", md: "60%" } }}
            InputProps={{
              style: { borderRadius: "50px 0px 0px 50px" },
              endAdornment: searchTerm.length > 0 && (
                <IconButton onClick={handleClear} color="primary">
                  <Clear />
                </IconButton>
              ),
            }}
          />
          <TextField
            select
            // label="Location"
            value={selectedLocation}
            onChange={handleLocationChange}
            variant="outlined"
            size="small"
            sx={{ width: { xs: "30%", md: "40%" } }}
            SelectProps={{
              native: true,
            }}
            InputProps={{
              style: { borderRadius: "0px 50px 50px 0px" },
              startAdornment: (
                <LocationOnRounded style={{ color: "lightgrey" }} />
              ),
            }}
          >
            <option value="">All Locations</option>
            {locationsUS.map((location) => (
              <option key={location.name} value={location.value}>
                {location.name}
              </option>
            ))}
          </TextField>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
          onClick={handleSearch}
          sx={{
            borderRadius: "50px",
            height: "36px",
            marginTop: { xs: 1, md: 0 },
          }}
        >
          Search
        </Button>
      </Grid>

      <Grid
        item
        container
        xs={12}
        sx={{
          marginBottom: "20px",
          borderBottom: "1px solid lightgrey",
          justifyContent: { xs: "flex-end", md: "center" },
          padding: 1,
        }}
        spacing={1}
      >
        <Typography
          variant="caption"
          sx={{
            width: "100%",
            textAlign: { xs: "left", md: "center" },
            paddingLeft: { xs: 1, md: 0 },
          }}
        >
          Categories
        </Typography>
        {[
          "Music",
          "Nightlife",
          "Food",
          "Drink",
          "Concert",
          "Virtual Event",
          "Fun",
          "Romantic",
        ].map((tag) => (
          <Grid item key={tag}>
            <Chip
              label={tag}
              clickable
              color={tagsFilters.includes(tag) ? "primary" : "default"}
              onClick={() => handleTagClick(tag)}
            />
          </Grid>
        ))}
        <Grid item>
          <Chip
            label={`More Categories ${
              tagsFilters.length > 0 ? `(${tagsFilters.length})` : ""
            }`}
            clickable
            color="primary"
            variant="outlined"
            onClick={() => setFilterModalOpen(true)}
          />
        </Grid>
        {tagsFilters.length > 0 && (
          <Grid item>
            <IconButton onClick={() => setTagsFilters([])}>
              <Clear />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid container justifyContent={"center"}>
        {currentEvents.length > 0 ? (
          currentEvents.map((event) => (
            <Grid
              item
              xs={11}
              md={4}
              lg={3}
              key={event.id}
              style={{ padding: 10 }}
            >
              <Card>
                <CardContent style={{ padding: 0 }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: { xs: 150, md: "25dvh" } }}
                  >
                    <img
                      src={event.image}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      p: 2,
                      height: { xs: "30dvh", md: "25dvh" },
                      position: "relative",
                    }}
                  >
                    <Grid item container xs={12} sx={{ height: "fit-content" }}>
                      <Typography variant="h6" style={{ textAlign: "left" }}>
                        {event.title}
                      </Typography>
                      <Typography variant="body2" style={{ textAlign: "left" }}>
                        {event.date}
                      </Typography>
                      <Typography variant="body2" style={{ textAlign: "left" }}>
                        <strong>{"Location: "}</strong>
                        {event.location}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ textAlign: "left" }}
                      >
                        <strong>{"About: "}</strong>
                        {event?.teaser?.length > 120
                          ? event?.teaser?.slice(0, 120) + "..."
                          : event?.teaser}
                      </Typography>
                    </Grid>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        marginTop: "1rem",
                        height: "38px",
                        position: "absolute",
                        bottom: 10,
                        left: 10,
                        borderRadius: 40,
                      }}
                      component={Link}
                      to={`/events/${event.title
                        .toLowerCase()
                        .split(" ")
                        .join("-")}_ev_${event.id}`}
                    >
                      View Details
                    </Button>
                    <Grid
                      item
                      container
                      xs={6}
                      style={{
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <TimerOutlined style={{ marginRight: 5 }} />
                      <Typography variant="caption">
                        {new Date(
                          event?.startDate?.seconds * 1000
                        ).toLocaleDateString()}
                        <br />
                        {new Date(
                          event?.startDate?.seconds * 1000
                        ).toLocaleTimeString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h6">No events found.</Typography>
        )}
      </Grid>

      {/* Pagination Controls */}
      <Grid item container xs={12} justifyContent="center" sx={{ mt: 2 }}>
        <IconButton onClick={paginateBackward} disabled={currentPage === 1}>
          <ArrowBack />
        </IconButton>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Page {currentPage}
        </Typography>
        <IconButton
          onClick={paginateForward}
          disabled={currentPage * eventsPerPage >= searchFilterEvents?.length}
        >
          <ArrowForward />
        </IconButton>
      </Grid>
      {/* Tag Filter Modal */}
      <Modal open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <Grid
          item
          container
          xs={11}
          md={8}
          lg={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: 12,
            background: "white",
            padding: 20,
          }}
        >
          <Typography variant="h6" style={{ marginBottom: 16 }}>
            Filter by Tags
          </Typography>
          <Grid
            container
            spacing={1}
            style={{ maxHeight: "70dvh", overflowY: "scroll" }}
          >
            {eventTags.map((tag) => (
              <Grid item key={tag}>
                <Chip
                  label={tag}
                  clickable
                  color={tagsFilters.includes(tag) ? "primary" : "default"}
                  onClick={() => handleTagClick(tag)}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={applyTagFilters}
              sx={{ marginRight: 1 }}
            >
              Apply Filters
            </Button> */}
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setFilterModalOpen(false);
                setTagsFilters([]);
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default Events;
