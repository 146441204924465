// src/components/Notifications.js
import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const Notifications = () => {
  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
        Notifications
      </Typography>
      <List>
        {/* Map over notifications here */}
        <ListItem>
          <ListItemText
            primary="Notification 1"
            secondary="Details about notification 1"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Notification 2"
            secondary="Details about notification 2"
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default Notifications;
