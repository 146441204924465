// src/components/HelpCenter.js
import React from "react";
import { Box, Typography } from "@mui/material";

const HelpCenter = () => {
  return (
    <Box sx={{ marginBottom: "2rem" }}>
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
        Help Center
      </Typography>
      <Typography variant="body1">
        Find answers to frequently asked questions...
      </Typography>
    </Box>
  );
};

export default HelpCenter;
