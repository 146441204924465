import React from "react";
import { Container, Typography } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container style={{ paddingTop: 120, paddingBottom: 50 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" paragraph>
        Effective Date: 6/15/2024
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing and using the TicketShare website and services, you accept
        and agree to be bound by the terms and provisions of this agreement. If
        you do not agree to abide by these Terms of Service, please do not use
        this site or our services.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. Description of Services
      </Typography>
      <Typography variant="body1" paragraph>
        TicketShare provides artists with a platform to sell tickets directly to
        fans. The services include ticket sales, revenue tracking, and other
        related features as described on our website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. User Accounts
      </Typography>
      <Typography variant="body1" paragraph>
        To use certain features of our services, you may be required to create
        an account and provide information about yourself. You agree to:
        <ul>
          <li>
            Provide true, accurate, current, and complete information about
            yourself.
          </li>
          <li>
            Maintain and promptly update your information to keep it true,
            accurate, current, and complete.
          </li>
          <li>
            Be responsible for maintaining the confidentiality of your account
            and password and for restricting access to your computer.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Use of Services
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to use the services only for lawful purposes and in accordance
        with these Terms of Service. You agree not to:
        <ul>
          <li>
            Use the services in any way that violates any applicable federal,
            state, local, or international law or regulation.
          </li>
          <li>
            Engage in any conduct that restricts or inhibits anyone's use or
            enjoyment of the services, or which, as determined by us, may harm
            TicketShare or users of the services.
          </li>
          <li>
            Use any robot, spider, or other automatic devices, process, or means
            to access the services for any purpose, including monitoring or
            copying any material on the services.
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs, or other
            material which is malicious or technologically harmful.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Payments and Fees
      </Typography>
      <Typography variant="body1" paragraph>
        All payments for services provided by TicketShare are subject to our
        payment terms and policies. You agree to pay all fees and charges
        incurred in connection with your use of our services, including any
        applicable taxes.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Pricing
      </Typography>
      <Typography variant="body1" paragraph>
        Creating events and tickets is a free service; however, all non-free
        tickets will be subject to a ticketing fee. Stripe is used for payment
        processing, and the event organizer is responsible for any associated
        fees. TicketShare may also charge a percentage of each ticket sale as a
        service fee, which will be detailed during the event setup process.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Intellectual Property Rights
      </Typography>
      <Typography variant="body1" paragraph>
        The services and their entire contents, features, and functionality are
        owned by TicketShare, its licensors, or other providers of such material
        and are protected by copyright, trademark, patent, trade secret, and
        other intellectual property or proprietary rights laws.
      </Typography>
      <Typography variant="h6" gutterBottom>
        8. Content & Trademarks
      </Typography>
      <Typography variant="body1" paragraph>
        By uploading content to our platform, you grant TicketShare a
        non-exclusive, transferable, sublicensable, royalty-free, worldwide
        license to use, display, reproduce, and distribute your content. This
        includes, but is not limited to, promotional materials, event listings,
        and advertisements.
        <br />
        You represent and warrant that you have all the necessary rights,
        licenses, and permissions to grant this license to TicketShare. You also
        agree that your content will not infringe or violate the rights of any
        third party, including intellectual property, privacy, and publicity
        rights.
      </Typography>
      <Typography variant="h6" gutterBottom>
        9. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We may terminate or suspend your account and access to the services
        immediately, without prior notice or liability, if you breach these
        Terms of Service. Upon termination, your right to use the services will
        immediately cease.
      </Typography>
      <Typography variant="h6" gutterBottom>
        10. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        To the fullest extent permitted by applicable law, in no event shall
        TicketShare, its affiliates, directors, employees, or licensors be
        liable for any indirect, incidental, special, consequential, or punitive
        damages, including without limitation, loss of profits, data, use,
        goodwill, or other intangible losses, resulting from:
        <ul>
          <li>Your use or inability to use the services..</li>
          <li>
            Any unauthorized access to or use of our servers and/or any personal
            information stored therein.
          </li>
          <li>
            Any interruption or cessation of transmission to or from our
            services.
          </li>
          <li>
            Any bugs, viruses, Trojan horses, or the like that may be
            transmitted to or through our services by any third party.
          </li>
          <li>
            Any errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through the services.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" gutterBottom>
        11. Cookies
      </Typography>
      <Typography variant="body1" paragraph>
        TicketShare uses cookies and similar tracking technologies to enhance
        your experience on our website. Cookies help us understand user
        behavior, remember your preferences, and improve our services. By using
        our website, you agree to our use of cookies in accordance with our
        Privacy Policy. You can manage your cookie preferences through your
        browser settings.
      </Typography>
      <Typography variant="h6" gutterBottom>
        12. Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to indemnify, defend, and hold harmless TicketShare, its
        affiliates, officers, directors, employees, agents, licensors, and
        suppliers from and against all claims, losses, expenses, damages, and
        costs, including reasonable attorneys' fees, resulting from any
        violation of these Terms of Service, your use of the services, or any
        activity related to your account by you or any other person accessing
        the services using your account.
      </Typography>
      <Typography variant="h6" gutterBottom>
        13. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms of Service and any dispute or claim arising out of, or
        related to them, shall be governed by and construed in accordance with
        the internal laws of the state of Georgia, without giving effect to any
        choice or conflict of law provision or rule.
      </Typography>
      <Typography variant="h6" gutterBottom>
        14. Changes to Terms of Service
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify or replace these Terms of Service at any
        time. We will notify you of any changes by posting the new Terms of
        Service on this page. It is your responsibility to review these Terms of
        Service periodically for any changes. Your continued use of or access to
        the services following the posting of any changes constitutes acceptance
        of those changes.
      </Typography>
      <Typography variant="h6" gutterBottom>
        15. Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this Privacy Policy, please contact us
        at:
        <ul>
          <li>Email: antony@cutiapp.com</li>
          {/* <li>Phone: [Insert Contact Email]</li> */}
        </ul>
      </Typography>
      {/* Add other sections in similar fashion */}
    </Container>
  );
};

export default TermsOfService;
