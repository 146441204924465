// src/screens/HomeScreen.js
import React, { useContext, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion";
import Hero from "../components/Hero";
import About from "../components/About";
import Contact from "../components/Contact";
import { AppContext } from "../App";

const HomeScreen = ({ navigate, homeRef, showsRef, contactRef }) => {
  const { setViewMenu } = useContext(AppContext);

  useEffect(() => {
    setViewMenu(true);
  }, []);

  return (
    <Grid item container xs={12} style={{ height: "fit-content", zIndex: 1 }}>
      <Hero homeRef={homeRef} />
      <About />
      {/* <Contact contactRef={contactRef} /> */}
    </Grid>
  );
};

export default HomeScreen;
